@import 'src/theme/color';
@import 'src/theme/breakpoints';
@import 'src/theme/font';

.componentWrapper {
  .summary {
    background: $color-white;
    padding: 20px 10px;
    display: flex;
    align-items: stretch;
    justify-content: space-between;

    @include breakpoint(md) {
      padding: 0;
      flex-wrap: wrap;
      margin: 0 16px;
    }

    .box {
      padding: 0 15px;
      border-right: 1px solid $color-gray-border;
      width: 33.33%;
      color: $color-text;

      @include breakpoint(md) {
        padding: 8px;
        width: 50%;
        height: 91px;
        border-right: none;

        &:nth-child(1),
        &:nth-child(2) {
          border-bottom: 1px solid $color-gray-border;
        }

        &:nth-child(odd) {
          border-right: 1px solid $color-gray-border;
        }
      }

      &:not(&:first-child) {
        .value {
          text-align: right;
        }
      }

      &:last-of-type {
        border-right: none;
      }

      .value {
        display: block;
        font-size: 24px;
        font-style: normal;
        font-weight: 700;
        line-height: 120%;
        letter-spacing: 0.24px;
        color: $color-text-dark;
        margin-top: 10px;

        @include breakpoint(md) {
          text-align: right;
        }

        &.red {
          color: $color-error;
        }
      }
    }
  }

  .listWrapper {
    margin: 15px 0;
    background: $color-gray-background;

    &.invoices {
      margin-top: 0;
    }

    .listHeader {
      display: flex;
      align-items: center;
      justify-content: space-between;
      background: linear-gradient(89.98deg, #757575 0.02%, #939393 99.99%);
      border-radius: 4px 4px 0 0;
      height: 42px;
      padding: 0 16px;
      color: $color-white;
      font-size: 16px;
      background: $color-navy;

      .link {
        display: flex;
        align-items: center;
        justify-content: center;
        color: $color-white;
        text-decoration: none;
        font-size: 14px;
        line-height: 2em;

        svg {
          margin-left: 10px;
        }
      }
    }

    .listTabs {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-top: 16px;

      @include breakpoint(md) {
        border-bottom: 1px solid $color-gray-border;
      }

      .tab {
        background: $color-gray-border;
        width: calc(100% / 3 - 10px);
        height: 45px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: $color-text;
        border-bottom: 3px solid $color-gray-border;
        cursor: pointer;

        &.active {
          background: $color-white;
          border-color: $color-orange;
          color: $color-text-dark;
          font-weight: 600;
        }
      }
    }

    .tableWrapper {
      th {
        padding: 8px 16px;
      }

      td {
        font-size: 14px;
        padding: 8px 16px;
      }

      .primaryText {
        color: $color-primary;
      }

      .grayText {
        color: $color-text;
      }

      .redText {
        color: $color-error;
      }
    }

    .mobileListItem {
      .itemHeader {
        color: $color-primary;
        border-bottom: 1px solid $color-gray-border;
        height: 36px;
        padding: 0 16px;
        display: flex;
        align-items: center;
      }

      .details {
        border-bottom: 1px solid $color-gray-border;
        display: flex;
        align-items: center;
        padding: 8px 0;

        > div {
          width: 100%;
          padding: 8px;
          display: flex;
          align-items: center;
          justify-content: center;
          flex-direction: column;
          border-right: 1px solid $color-gray-border;

          &:last-child {
            border-right: none;
          }

          .label {
            color: $color-text-light;
          }
        }
      }
    }

    .listFooter {
      .summary {
        margin: 0;

        .title {
          margin-top: 16px;
          font-size: 18px;
          font-style: normal;
          font-weight: 700;
          line-height: 140%;
          letter-spacing: 0.09px;
          color: $color-text-dark;
        }

        .items {
          padding: 15px 0;
          display: flex;
          align-items: center;

          > div {
            margin-right: 16px;
            color: $color-text-dark;
            font-weight: 700;

            span {
              color: $color-text;
              font-weight: 400;
            }
          }
        }
      }

      .total {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        background-color: $color-white;
        margin-top: 4px;
        padding: 20px 10px;

        .title {
          font-size: 18px;
          font-style: normal;
          font-weight: 700;
          line-height: 140%;
          letter-spacing: 0.09px;
          color: $color-text-dark;
        }

        .prices {
          display: flex;
          align-items: flex-end;
          flex-direction: column;
          color: $color-text;

          .gross {
            font-size: 16px;
            color: $color-text-dark;
          }
        }
      }
    }
  }

  .invoice {
    margin: 0 16px;
    border-bottom: 1px dashed $color-gray-border;

    &:last-of-type {
      border-bottom: none;
    }

    .header {
      cursor: pointer;
      padding: 12px 0;
      display: flex;
      align-items: center;
      justify-content: space-between;
      color: $color-text;

      svg {
        width: 14px;
        height: 14px;
      }

      &.expanded {
        color: $color-primary;

        svg {
          transform: rotate(180deg);
        }
      }
    }

    .content {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      padding-bottom: 12px;

      .box {
        text-align: center;
        padding: 0 4px;
        color: $color-text;

        .label {
          font-size: 12px;
          margin-bottom: 5px;
        }
      }
    }
  }
}

.progress {
  width: 7px;
  height: 30px;
  border: 1px solid #cccfd5;
  display: inline-flex;
  flex-direction: column;
  border-radius: 1px;
  margin-right: 5px;
}

.tradeCredit {
  display: flex !important;
  align-items: center;
  justify-content: flex-end;
}

.tradeCreditValue {
  font-size: 14px;
  font-style: normal;
  font-weight: 300;
  line-height: 140%;

  & > span {
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 120%;
    letter-spacing: 0.24px;
    color: #02ad3e;
  }
}

.symbol a {
  color: $color-secondary;
  text-decoration: none !important;
}
